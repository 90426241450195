import React, { useState } from "react";
import { db } from "../firebaseConfig";
import { addDoc, collection } from "firebase/firestore";

const TripForm: React.FC = () => {
  const [distance, setDistance] = useState("");
  const [gasPrice, setGasPrice] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [selectedName, setSelectedName] = useState("Peyton");

  const calculateAndAddTrip = async () => {
    try {
      const response = await fetch("api/calculate-gas", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          distance: parseFloat(distance),
          gasPrice: parseFloat(gasPrice),
        }),
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const { cost } = await response.json();

      // Include selectedName and isPaid in the document
      await addDoc(collection(db, "trips"), {
        name: selectedName,
        description,
        date: new Date(date),
        distance: parseFloat(distance),
        gasPrice: parseFloat(gasPrice),
        cost, // Calculated cost
        createdAt: new Date(),
      });

      console.log("Trip added successfully with cost!");
      // Optionally clear form fields or handle state updates
    } catch (error) {
      console.error("Error adding trip: ", error);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    calculateAndAddTrip();
  };
  

  return (
    <form onSubmit={handleSubmit} className="space-y-6 m-10">
      {/* Dropdown for selecting names */}
      <div>
        <label htmlFor="name-select" className="block text-gray-700 text-sm font-bold mb-2">
          Name
        </label>
        <select
          id="name-select"
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
          className="shadow border rounded w-full py-2 px-3 text-gray-700"
          required
        >
          <option value="Peyton">Peyton</option>
          <option value="Keira">Keira</option>
        </select>
      </div>

      <div className="mb-6">
        <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
          Description
        </label>
        <textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="date" className="block text-gray-700 text-sm font-bold mb-2">
          Date
        </label>
        <input
          type="date"
          id="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-4">
        <label htmlFor="distance" className="block text-gray-700 text-sm font-bold mb-2">
          Distance (miles)
        </label>
        <input
          type="number"
          id="distance"
          value={distance}
          onChange={(e) => setDistance(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <div className="mb-6">
        <label htmlFor="gasPrice" className="block text-gray-700 text-sm font-bold mb-2">
          Gas Price (per gallon)
        </label>
        <input
          type="number"
          id="gasPrice"
          value={gasPrice}
          onChange={(e) => setGasPrice(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
      </div>

      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Add Trip
      </button>
    </form>
  );
};

export default TripForm;
