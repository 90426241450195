// In src/App.tsx

import React from 'react';
import TripForm from './components/TripForm';
import TripList from './components/TripList';

const App: React.FC = () => {
  return (
    <div className="App">
      <TripForm />
      <TripList />
    </div>
  );
}

export default App;
