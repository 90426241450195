import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  deleteDoc,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";

const TripList: React.FC = () => {
  const [trips, setTrips] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("unpaid"); // 'paid' or 'unpaid'

  const removeTrip = async (id: string) => {
    const tripDocRef = doc(db, "trips", id);
    await deleteDoc(tripDocRef);
  };

  const togglePaidStatus = async (id: string, isPaid: boolean) => {
    const tripDocRef = doc(db, "trips", id);
    await updateDoc(tripDocRef, {
      paid: !isPaid,
    });
  };

  const formatDateToLocal = (firebaseTimestamp: Timestamp) => {
    const isoDateString = firebaseTimestamp.toDate().toISOString().split("T")[0];
    const [year, month, day] = isoDateString.split("-");
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const q = query(collection(db, "trips"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const tripsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTrips(tripsData);
      },
      (error) => console.log(error)
    );

    return () => unsubscribe();
  }, []);

  const filteredTrips = trips.filter((trip) =>
    activeTab === "paid" ? trip.paid : !trip.paid
  );
  const totalUnpaid = trips
    .filter((trip) => !trip.paid)
    .reduce((acc, trip) => acc + parseFloat(trip.cost || 0), 0)
    .toFixed(2);

  return (
    <div className="max-w-4xl mx-auto py-8">
      <h2 className="text-2xl font-semibold mb-4">Trips</h2>

      <div className="mb-4 flex justify-center space-x-4">
        <button
          onClick={() => setActiveTab("unpaid")}
          className={`py-2 px-4 font-semibold rounded-lg ${
            activeTab === "unpaid" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-800"
          }`}
        >
          Unpaid
        </button>
        <button
          onClick={() => setActiveTab("paid")}
          className={`py-2 px-4 font-semibold rounded-lg ${
            activeTab === "paid" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-800"
          }`}
        >
          Paid
        </button>
      </div>

      {activeTab === "unpaid" && (
        <div className="text-gray-800 mb-4">Total Unpaid: ${totalUnpaid}</div>
      )}

      <ul className="space-y-4">
        {filteredTrips.map((trip) => (
          <li key={trip.id} className="bg-white shadow overflow-hidden rounded-lg p-4 flex justify-between items-center">
            <div>
              <div className="text-lg font-bold">{trip.name}</div>
              <p className="text-gray-600">{trip.description}</p>
              <p className="text-gray-500">Date: {formatDateToLocal(trip.date)}</p>
              <p className="text-gray-500">Distance: {trip.distance}, Gas Price: {trip.gasPrice}, Cost: ${parseFloat(trip.cost).toFixed(2)}</p>
            </div>
            <div className="flex items-center">
              <label htmlFor={`paid-${trip.id}`} className="flex items-center mr-4 cursor-pointer">
                <input
                  type="checkbox"
                  id={`paid-${trip.id}`}
                  className="form-checkbox h-5 w-5 text-blue-600"
                  checked={trip.paid}
                  onChange={() => togglePaidStatus(trip.id, trip.paid)}
                />
                <span className="ml-2 text-gray-700">Paid</span>
              </label>
              <button
                onClick={() => removeTrip(trip.id)}
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Remove
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TripList;
